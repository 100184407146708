
import { defineComponent } from "vue";

// Import close icon
import { Icon } from "@iconify/vue";
import closeIcon from "@iconify-icons/line-md/close";

export default defineComponent({
  name: "MobileMenu",
  components: {
    Icon,
  },
  props: {
    showMenu: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      icons: {
        close: closeIcon,
      },
    };
  },
});
