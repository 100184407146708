
import { defineComponent, ref, computed } from "vue";

import { Icon } from "@iconify/vue";
import getIcon from "./getIcon";
import sendIcon from "@iconify-icons/tabler/send";

import Button from "./components/Button.vue";

export default defineComponent({
  name: "Skills",
  components: {
    "skill-btn": Button,
    Icon,
  },
  setup() {
    const frontend = ref(true);
    const backend = ref(false);
    const ui = ref(false);

    const changeSkill = (e: any) => {
      const text = e.srcElement.innerText;

      frontend.value = false;
      backend.value = false;
      ui.value = false;

      if (text === "Frontend") {
        frontend.value = true;
      } else if (text === "Backend") {
        backend.value = true;
      } else {
        ui.value = true;
      }
    };

    const email = ref("");
    const contactClicked = () => {
      const input: HTMLInputElement | null = document.querySelector(
        "#contact input[type='email']"
      );
      if (!input) return;

      input.value = email.value;

      const event = new Event("input", {
        bubbles: true,
        cancelable: true,
      });
      input.dispatchEvent(event);
    };

    return {
      frontend,
      backend,
      ui,
      changeSkill,
      email,
      contactClicked,
      icons: {
        tl: computed(() => getIcon(frontend.value, backend.value, "tl")),
        tr: computed(() => getIcon(frontend.value, backend.value, "tr")),
        bl: computed(() => getIcon(frontend.value, backend.value, "bl")),
        br: computed(() => getIcon(frontend.value, backend.value, "br")),
        send: sendIcon,
      },
      show: true,
    };
  },
});
