
import { defineComponent } from "vue";

import { Icon } from "@iconify/vue";
import menuIcon from "@iconify-icons/line-md/menu";

export default defineComponent({
  name: "Navbar",
  components: {
    Icon,
  },
  setup() {
    return {
      icons: {
        menu: menuIcon,
      },
    };
  },
});
