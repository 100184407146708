
import { defineComponent } from "vue";

export default defineComponent({
  name: "Card",
  props: {
    name: String,
    description: String,
    code: String,
    hideCode: Boolean,
    site: String,
    image: String,
  },
});
