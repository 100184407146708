
import { defineComponent, onMounted, ref } from "vue";

import { Icon } from "@iconify/vue";
import chevronIcon from "@iconify-icons/line-md/chevron-small-right";

import Typewriter from "typewriter-effect/dist/core";
import {
  setupTypewriterDesktop,
  setupTypewriterMobile,
} from "./setupTypewriter";

export default defineComponent({
  name: "Hero",
  components: {
    Icon,
  },
  setup() {
    const role = ref<HTMLSpanElement>();

    onMounted(() => {
      // setup typewriter effect
      const tw = new Typewriter(role.value, {
        delay: 80,
        loop: true,
      });

      const delay = 1600;
      const pause = 300;

      if (window.innerWidth <= 700) {
        setupTypewriterMobile(tw, delay, pause);
      } else {
        setupTypewriterDesktop(tw, delay, pause);
      }
    });

    return {
      role,

      icons: {
        chevron: chevronIcon,
      },
    };
  },
});
