
import { defineComponent, ref } from "vue";

import { Icon } from "@iconify/vue";
import twitterIcon from "@iconify-icons/line-md/twitter";
import emailIcon from "@iconify-icons/line-md/email";
import githubIcon from "@iconify-icons/line-md/github";
import linkedInIcon from "@iconify-icons/line-md/linkedin";

import InputBox from "./components/InputBox.vue";

export default defineComponent({
  name: "Contact",
  components: {
    InputBox,
    Icon,
  },
  setup() {
    const email = ref("");
    const subject = ref("");
    const message = ref("");

    return {
      email,
      subject,
      message,

      icons: {
        twitter: twitterIcon,
        email: emailIcon,
        github: githubIcon,
        linkedIn: linkedInIcon,
      },
    };
  },
});
