
import { defineComponent, onBeforeMount, ref } from "vue";

import Curve from "./components/Curve.vue";
import Card from "./components/Card.vue";

// project images
const blaze2dImage: string = require("../../assets/blaze2d.webp");
const unoImage: string = require("../../assets/uno.webp");
const haggisImage: string = require("../../assets/haggis-lang.webp");
const smmImage: string = require("../../assets/scoot-map-manager.webp");
const sliderImage: string = require("../../assets/vue3-slider.webp");
const shortyImage: string = require("../../assets/shorty.lol.webp");

export default defineComponent({
  name: "Projects",
  components: {
    Curve,
    Card,
  },
  setup() {
    const scuffedUnoPlays = ref(2800000);
    const fetchScuffedUnoPlays = async () => {
      scuffedUnoPlays.value = await fetch(
        "https://gist.githubusercontent.com/freddie-nelson/3fbffb9c94575acd9aac4d1c58b8b8d0/raw/scuffed-uno-stats.json"
      )
        .then(async (res) => (await res.json()).totalVisits)
        .catch((err) => (console.log(err), {}));
    };
    onBeforeMount(fetchScuffedUnoPlays);

    return {
      scuffedUnoPlays,

      haggisImage,
      blaze2dImage,
      unoImage,
      shortyImage,
      sliderImage,
      smmImage,
    };
  },
});
