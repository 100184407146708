
import { defineComponent } from "vue";

import { Icon } from "@iconify/vue";
import heartIcon from "@iconify-icons/el/heart";

export default defineComponent({
  name: "Footer",
  components: {
    Icon,
  },
  setup() {
    return {
      icons: {
        heart: heartIcon,
      },
    };
  },
});
